import FormTooltip from "../form/FormTooltip";
import { priceFormatter } from "../../utils/PriceFormatter";

function ResultsBadge({ size, format, result, label, help }) {
  const textSize = size === "large" ? "text-xl" : "text-lg";

  const formatResult = (format, result) => {
    if (format === "price") return result ? priceFormatter(result) : "£0.00";

    if (format === "percentage")
      return result ? percentageFormatter(result) : "0.00%";
  };

  const percentageFormatter = (value) => {
    return value.toFixed(2) + "%";
  };

  return (
    <>
      <div className="flex items-center justify-between px-0 py-3">
        <p className="text-md text-firgreen">{label}</p>
        <div className="ml-2 flex items-center">
          <span
            className={`font-semibold text-firgreen ${textSize} whitespace-nowrap leading-none`}
          >
            {formatResult(format, result)}
          </span>

          {help && (
            <div className="ml-2">
              <FormTooltip text={help} color="white" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ResultsBadge;
