import React, { useState, useEffect, Fragment } from "react";
import { Routes, Route, useSearchParams } from "react-router-dom";
import axios from "axios";
import { orderBy, throttle } from "lodash";
import CalculationsPage from "./pages/CalculationsPage";
import ContactPage from "./pages/ContactPage";
import NotFoundPage from "./pages/NotFoundPage";
import AppContext from "./Context/AppContext";

function App() {
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState({});
  const [appointment, setAppointment] = useState(false);
  const [rates, setRates] = useState([]);
  //const [lowestRates, setLowestRates] = useState([]);
  const [pageHeight, setPageHeight] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      reportPageHeight();

      setPageHeight(false);
      // console.log(
      //   "DELAYED resize reportPageHeight",
      //   document.body.scrollHeight,
      // );
    }, 1000);
  }, []);
  
  useEffect(() => {
    reportPageHeight();

    setPageHeight(false);
  }, [pageHeight, formData]);

  useEffect(() => {
    const handleResize = throttle(() => {
      reportPageHeight();
    }, 500);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const reportPageHeight = () => {
    window.parent.postMessage(document.body.scrollHeight, "*");
    //console.log("resize reportPageHeight", document.body.scrollHeight);
  };

  useEffect(() => {
    const fetchRates = async () => {
      try {
        await Promise.all([
          axios.get(
            process.env.REACT_APP_API_URL + "/calc-int-rates",
          ),
        ]).then((response) => {

           const data = response[0].data;

           setRates(data);
        });
      } catch (ex) {
        console.log("The rates could not be loaded", ex);
      }
    };
    fetchRates();
  }, []);

  useEffect(() => {
    if (!formData.propertyPurchasePrice) {
      // const price = searchParams.get("price")
      //   ? Number(searchParams.get("price"))
      //   : 300000;
      const price = Number(searchParams.get("price"));

      const houseType = searchParams.get("type")
        ? searchParams.get("type")
        : "";
        const developmentName = searchParams.get("developmentName")
        ? searchParams.get("developmentName")
        : "";
      const development = searchParams.get("development")
        ? searchParams.get("development")
        : "";
      const company = searchParams.get("company")
        ? searchParams.get("company")
        : "";

      const plot = searchParams.get("plot")
        ? searchParams.get("plot")
        : "";
        
        const brand = searchParams.get("brand")
        ? searchParams.get("brand")
        : "";


      setFormData({
        propertyPurchasePrice: price,
        developmentName,
        development,
        houseType,
        company,
        plot,
        brand
      });
    }
  }, []);

  const handleFormData = (updatedData) => {
    setFormData({ ...formData, ...updatedData });
  };

  const handleAppointment = (boolean) => {
    setAppointment(boolean);
  };

  const handlePageHeight = (boolean) => setPageHeight(boolean);

  return (
    <AppContext.Provider
      value={{
        formData: formData,
        setFormData: handleFormData,
        appointment: appointment,
        setAppointment: handleAppointment,
        searchParams: searchParams,
        rates: rates,
        setPageHeight: handlePageHeight,
      }}
    >
      <Fragment>
        <main>
          <Routes>
            <Route path="/" element={<CalculationsPage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </main>
      </Fragment>
    </AppContext.Provider>
  );
}

export default App;
