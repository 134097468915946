import React from "react";
import NumberFormat from "react-number-format";

export function priceFormatter(value) {
  return (
    <NumberFormat
      value={value}
      displayType={"text"}
      thousandSeparator={true}
      prefix={"£"}
      decimalScale={0}
      // fixedDecimalScale={true}
    />
  );
}
