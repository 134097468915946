import React, { useContext, useState, useEffect, Fragment } from "react";
import NumberFormat from "react-number-format";
import ResultsBadge from "./ResultsBadge";
import { calculateMortgage } from "../../utils/calculators";
// import { priceFormatter } from "../../utils/PriceFormatter";
import { ButtonSmall } from "../Buttons";
//import settings from "../../data/settings.json";
import AppContext from "../../Context/AppContext";

function MortgageCalculator({
  formData,
  //calculationsData,
  calculatedLaonAmount,
}) {
  const { rates } = useContext(AppContext);

  const [calculatorData, setCalculatorData] = useState({
    calcMortgageLoanAmount: 0,
    calcMortgageTerm: 30,
    calcInterestRate: rates[0],
  });
  const [calculatorResults, setCalculatorResults] = useState({});

  useEffect(() => {
    try {
      //const key = formData.helpToBuy !== 1 ? 0 : 2;
      if (calculatedLaonAmount) {
        const data = { ...calculatorData };

        data.calcMortgageLoanAmount = calculatedLaonAmount;

        setCalculatorData(data);
        setCalculatorResults(
          calculateMortgage(
            data.calcMortgageLoanAmount,
            data.calcMortgageTerm,
            data.calcInterestRate,
          ),
        );
      }
    } catch {
      return false;
    }
  }, [calculatedLaonAmount]);

  useEffect(() => {
    const data = { ...calculatorData };

    data.calcInterestRate = formData.buyToLet
      ? rates[1]
      : rates[0];

    setCalculatorData(data);
  }, [formData]);

  const handleChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: currentTarget.value.replace(/[^0-9.-]+/g, ""),
      min: currentTarget.min,
      max: currentTarget.max,
    };

    if (input.min && input.max) {
      input.value =
        Number(input.value) > Number(input.max)
          ? input.max
          : input.value !== "" && Number(input.value) < Number(input.min)
            ? input.min
            : input.value;
    }

    let data = { ...calculatorData };

    data[input.name] = parseFloat(input.value);

    setCalculatorData(data);

    if (input.value) {
      setCalculatorResults(
        calculateMortgage(
          data.calcMortgageLoanAmount,
          data.calcMortgageTerm,
          data.calcInterestRate,
        ),
      );
      //handleHelpText(data);
    }
  };

  const handleReset = () => {
    const data = { ...calculatorData };

    data.calcMortgageLoanAmount = calculatedLaonAmount;
    data.calcMortgageTerm = 30;
    data.calcInterestRate = formData.buyToLet
    ? rates[1]
    : rates[0];

    setCalculatorData(data);
    setCalculatorResults(
      calculateMortgage(
        data.calcMortgageLoanAmount,
        data.calcMortgageTerm,
        data.calcInterestRate,
      ),
    );
  };

  const { calcMortgageLoanAmount, calcMortgageTerm, calcInterestRate } =
    calculatorData;

  const incrementButtons = (name, increment, max) => {
    const handleMinus = (name, increment) => {
      const data = { ...calculatorData };
      data[name] = data[name] - increment;
      setCalculatorData(data);
      setCalculatorResults(
        calculateMortgage(
          data.calcMortgageLoanAmount,
          data.calcMortgageTerm,
          data.calcInterestRate,
        ),
      );
    };
    const handlePlus = (name, increment) => {
      const data = { ...calculatorData };
      data[name] = data[name] + increment;
      setCalculatorData(data);
      setCalculatorResults(
        calculateMortgage(
          data.calcMortgageLoanAmount,
          data.calcMortgageTerm,
          data.calcInterestRate,
        ),
      );
    };

    return (
      <div className="mt-1 flex w-full justify-between">
        <button
          type="button"
          className="inline-flex items-center rounded-full bg-white px-4 py-2 text-gray hover:bg-gray/10 disabled:bg-gray/10 disabled:text-gray/50"
          disabled={calculatorData[name] <= increment}
          onClick={() => handleMinus(name, increment)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <button
          type="button"
          className="inline-flex items-center rounded-full bg-white px-4 py-2 text-gray hover:bg-gray/10 disabled:bg-gray/10 disabled:text-gray/50"
          disabled={calculatorData[name] >= max}
          onClick={() => handlePlus(name, increment)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    );
  };

  return (
    <div className="mb-8 rounded-[10px] bg-stone p-4 md:mb-0">
      {/* <div className="mb-8"> */}
      <h2 className="mb-4 text-center font-heading text-3xl text-firgreen">
        Your mortgage repayments
      </h2>

      <Fragment>
        {/* {calcMortgageLoanAmount && calculatorResults[0] ? ( */}
        <div className="mb-4 divide-y divide-gray border-y border-gray">
          {/* <div className="relative"> */}
          <ResultsBadge
            size="large"
            format="price"
            result={calculatorResults[0]}
            label="Monthly payment, capital and interest"
            help={
              "This includes capital and interest payments. The total amount you have borrowed will be paid off by the end of your term."
            }
          />
          {/* <p className="absolute top-0 right-0">I</p>
          </div> */}

          {formData.helpToBuy !== 1 ? (
            <ResultsBadge
              size="large"
              format="price"
              result={calculatorResults[1]}
              label="Monthly payment, interest only"
              help={
                "This includes interest payments only. The total amount you have borrowed will not be reduced."
              }
            />
          ) : null}
        </div>
        {/* ) : null} */}
      </Fragment>
      {calculatedLaonAmount ? (
        <Fragment>
          <div className="mb-4">
            <label
              htmlFor="calcMortgageTermRange"
              className="text-md block text-gray"
            >
              Mortgage loan amount
            </label>
            <div className="flex items-center">
              {/* //////// */}
              <div className="flex grow flex-col items-start">
                <input
                  type="range"
                  name="calcMortgageLoanAmount"
                  id="calcMortgageLoanAmountRange"
                  maxLength={9}
                  min="1000"
                  max={calculatedLaonAmount * 2}
                  step="1000"
                  value={calcMortgageLoanAmount}
                  className="h-3 w-full appearance-none overflow-hidden rounded-[0.25rem] bg-white focus:shadow-none focus:outline-none focus:ring-0"
                  onChange={handleChange}
                />
              </div>
              {/* //////// */}
              <NumberFormat
                //type={type}
                name="calcMortgageLoanAmount"
                id="calcMortgageLoanAmountNumber"
                maxLength={9}
                min={1}
                max={calculatedLaonAmount * 2}
                value={calcMortgageLoanAmount}
                className="ml-4 w-[6rem] rounded-[0.25rem] border-firgreen bg-white text-gray focus:border-skyblue focus:ring-skyblue disabled:bg-gray/10"
                onChange={handleChange}
                //displayType={"text"}
                thousandSeparator={true}
                prefix={"£"}
                decimalScale={2}
                //allowEmptyFormatting={true}
                allowNegative={false}
              />
            </div>
            {incrementButtons(
              "calcMortgageLoanAmount",
              500,
              calculatedLaonAmount * 2,
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="calcMortgageTermRange"
              className="text-md block text-gray"
            >
              Mortgage term (years)
            </label>
            <div className="flex items-center">
              <input
                type="range"
                name="calcMortgageTerm"
                id="calcMortgageTermRange"
                maxLength={2}
                min="1"
                max="40"
                value={calcMortgageTerm}
                className="h-3 w-full appearance-none overflow-hidden rounded-[0.25rem] bg-white focus:shadow-none focus:outline-none focus:ring-0"
                onChange={handleChange}
              />
              <input
                type="number"
                name="calcMortgageTerm"
                id="calcMortgageTermNumber"
                maxLength={2}
                min={1}
                max={40}
                value={calcMortgageTerm}
                className="ml-4 w-[6rem] rounded-[0.25rem] border-firgreen bg-white text-gray focus:border-skyblue focus:ring-skyblue disabled:bg-gray/10"
                onChange={handleChange}
              />
            </div>

            {incrementButtons("calcMortgageTerm", 1, 40)}
          </div>
          <div className="mb-4">
            <label
              htmlFor="calcInterestRateRange"
              className="text-md block text-gray"
            >
              Interest rate (%)
            </label>
            <div className="flex items-center">
              <input
                type="range"
                name="calcInterestRate"
                id="calcInterestRateRange"
                maxLength="4"
                min="0.01"
                max="10"
                step="0.01"
                value={calcInterestRate}
                className="h-3 w-full appearance-none overflow-hidden rounded-[0.25rem] bg-white focus:shadow-none focus:outline-none focus:ring-0"
                onChange={handleChange}
              />
              <input
                type="number"
                name="calcInterestRate"
                id="calcMortgageTermNumber"
                maxLength={4}
                min={0.01}
                max={10}
                step={0.01}
                value={Number(calcInterestRate).toFixed(2)}
                className="ml-4 w-[6rem] rounded-[0.25rem] border-firgreen bg-white text-gray focus:border-skyblue focus:ring-skyblue disabled:bg-gray/10"
                onChange={handleChange}
              />
            </div>
            {incrementButtons("calcInterestRate", 0.1, 10)}
          </div>

          <div className="flex justify-end">
            <ButtonSmall
              color="coral"
              label="Reset"
              onClick={() => handleReset()}
            />
          </div>
        </Fragment>
      ) : null}
      {/* <div>
        <p className="text-gray text-xs">
          The above results can only be used as a guide. For an exact borrowing
          figure please contact us and we will provide a full review for you.
        </p>
      </div> */}
    </div>
  );
}

export default MortgageCalculator;
