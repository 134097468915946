import React, { useContext, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
//import ResultsDrawer from "../components/ResultsDrawer";
import FormMain from "../components/FormMain";
//import ContactDevelopment from "../components/widgets/ContactDevelopment";
import Results from "../components/Results.jsx";
import AppContext from "../Context/AppContext";
//import { ButtonDefault } from "../components/Buttons";

function CalculationsPage(props) {
  // let navigate = useNavigate();

  const { searchParams, appointment, setPageHeight } = useContext(AppContext);

  useEffect(() => {
    setPageHeight(true);
  }, []);

  return (
    <>
      {/* !!! remove margin and borders */}
      <div className="mx-auto md:max-w-[1566px] md:px-[15px]">
        <div className="grid grid-cols-1 gap-y-[30px] md:grid-cols-3 md:gap-[30px]">
          <div className="mx-auto mb-8 w-full px-[15px] sm:max-w-[503px] sm:px-0 md:mb-0">
            {/* <div className="border border-gray rounded-[10px] p-8 pb-2 mb-8"> */}
            <FormMain />
          </div>

          <div className="col-span-2">
            <Results />
          </div>
        </div>
      </div>
      {/* <ResultsDrawer /> */}
    </>
  );
}

export default CalculationsPage;
