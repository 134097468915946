import { useNavigate } from "react-router-dom";
import { ButtonLarge } from "../Buttons";

function GetInTouch(props) {
  let navigate = useNavigate();

  return (
    <>
      <div className="pt-8">
        <div className="flex justify-center">
          <ButtonLarge
            color="coral"
            label="Contact development"
            onClick={() => navigate("/contact")}
          />
        </div>
      </div>
    </>
  );
}

export default GetInTouch;
