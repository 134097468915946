import React, { useEffect, useState } from "react";
import Alert from "../Alert";
import { depositCheck } from "../../utils/depositCheck";

function DepositChecker({ formData, calculatedPropertyPurchasePrice }) {
  const [alerts, setAlerts] = useState({});

  useEffect(() => {
    try {
      const depositCheckResult = depositCheck(
        formData.propertyPurchasePrice,
        formData.deposit,
        formData.buyToLet,
      );

      setAlerts({ ...alerts, deposit: depositCheckResult });
    } catch {
      return false;
    }
  }, [formData, calculatedPropertyPurchasePrice]);

  return (
    <>
      {formData.propertyPurchasePrice > 0 && formData.deposit ? (
        <div className="mb-8 rounded-[10px] bg-stone p-4">
          <h2 className="mb-4 text-center font-heading text-3xl text-firgreen">
            Your deposit
          </h2>

          {alerts.deposit && (
            <Alert
              type={alerts.deposit[0]}
              text={alerts.deposit[1]}
              background={alerts.deposit[2]}
            />
          )}
        </div>
      ) : null}
    </>
  );
}

export default DepositChecker;
