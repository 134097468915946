export const calculateAffordability = (
  income,
  deposit,
  propertyRegion,
  regionPriceCap,
  buyToLet
) => {
  const resultBorrow = buyToLet ? deposit / 0.2 - deposit : income * 5;

  const resultPropertyValue = buyToLet
    ? deposit / 0.2
    : +resultBorrow + +deposit;

  const borrowHelpToBuy = income * 4.5;

  const helpToBuyIncomeDeposit = +borrowHelpToBuy + +deposit;

  const propertyValueHelpToBuy = +helpToBuyIncomeDeposit / 0.8;

  const resultPropertyValueHelpToBuy =
    propertyValueHelpToBuy >= regionPriceCap
      ? regionPriceCap
      : propertyValueHelpToBuy;

  const helpToBuyPercentage = propertyRegion === 1 ? 40 : 20;
  const resultHelpToBuyLoan =
    (resultPropertyValueHelpToBuy * helpToBuyPercentage) / 100;

  const resultBorrowHelpToBuy =
    propertyValueHelpToBuy >= regionPriceCap
      ? resultPropertyValueHelpToBuy - resultHelpToBuyLoan - deposit
      : borrowHelpToBuy;

  // console.log(
  //   "newPPP",
  //   resultPropertyValueHelpToBuy,
  //   (resultPropertyValueHelpToBuy * helpToBuyPercentage) / 100,
  //   deposit,
  //   resultPropertyValueHelpToBuy - resultHelpToBuyLoan - deposit
  // );

  // Purchase price £186,100.00
  // You could borrow £98,880
  // Help To Buy amount £37,220
  // Deposit £50,000

  return [
    resultBorrow,
    resultPropertyValue,
    resultBorrowHelpToBuy,
    resultHelpToBuyLoan,
    resultPropertyValueHelpToBuy,
  ];
};

export const calculateMortgage = (
  calcMortgageLoanAmount,
  calcMortgageTerm,
  calcInterestRate
) => {
  // Set constants and variables
  let mortgageLoanAmount = calcMortgageLoanAmount;
  let mortgageTerm = calcMortgageTerm;
  let interestRate = calcInterestRate;

  if (mortgageLoanAmount && mortgageTerm && interestRate) {
    let interestTerm;
    let resultCapAndInt;
    let resultIntOnly;

    interestRate = interestRate / 100 / 12; // convert interest to a decimal fraction then in a monthly rate
    mortgageTerm = mortgageTerm * 12; // convert mortgage term from years to months
    interestTerm = Math.pow(interestRate + 1, mortgageTerm); // calculate the interest term

    resultCapAndInt = (interestRate * interestTerm) / (interestTerm - 1); // solve the equation
    resultCapAndInt = mortgageLoanAmount * resultCapAndInt;

    resultIntOnly = (interestRate * interestTerm) / interestTerm; // solve the equation interest only
    resultIntOnly = mortgageLoanAmount * resultIntOnly;

    return [resultCapAndInt, resultIntOnly];
  }
};

export const calculateStampDuty = (
  calcPropertyPurchasePrice,
  calcBuyToLet,
  calcFirstTimeBuyer
) => {
  // Set constants and variables
  const pruchasePrice = calcPropertyPurchasePrice;
  const btlSecondHome = calcBuyToLet;
  const firstTimeBuyer = calcBuyToLet ? 0 : calcFirstTimeBuyer;

  let band;
  let taxableSum;
  let percent;
  let taxToPay;
  let btlSecondHomePercent;
  let btlSecondHomeTaxToPay;

  let result;

  // Determine switch statment
  if (firstTimeBuyer && pruchasePrice <= 625000) {
    switch (true) {
      case pruchasePrice <= 425000:
        band = 1;
        taxableSum = 0;
        percent = 0;
        taxToPay = 0;
        break;
      case pruchasePrice >= 425001 && pruchasePrice <= 625000:
        band = 2;
        taxableSum = 425000;
        percent = 5;
        taxToPay = 0 + 0;
        break;
    }
  } else {
    switch (true) {
      case pruchasePrice <= 250000:
        band = 1;
        taxableSum = 0;
        percent = 0;
        taxToPay = 0;
        btlSecondHomePercent = 3;
        btlSecondHomeTaxToPay = 0;
        break;
      case pruchasePrice >= 250001 && pruchasePrice <= 925000:
        band = 2;
        taxableSum = 250000;
        percent = 5;
        taxToPay = 0 + 0;
        btlSecondHomePercent = 8;
        btlSecondHomeTaxToPay = 0 + 7500;
        break;
      case pruchasePrice >= 925001 && pruchasePrice <= 1500000:
        band = 3;
        taxableSum = 925000;
        percent = 10;
        taxToPay = 0 + 0 + 33750;
        btlSecondHomePercent = 13;
        btlSecondHomeTaxToPay = 0 + 7500 + 54000;
        break;
      case pruchasePrice >= 1500001:
        band = 4;
        taxableSum = 1500000;
        percent = 12;
        taxToPay = 0 + 0 + 33750 + 57500;
        btlSecondHomePercent = 15;
        btlSecondHomeTaxToPay = 0 + 7500 + 54000 + 74750;
        break;
    }
  }

  // Calculate the result
  if (pruchasePrice) {
    if (btlSecondHome) {
      let remainder = pruchasePrice - taxableSum;
      result = (remainder * btlSecondHomePercent) / 100 + btlSecondHomeTaxToPay;
    } else {
      let remainder = pruchasePrice - taxableSum;
      result = (remainder * percent) / 100 + taxToPay;
    }
  }

  return [result, (result / pruchasePrice) * 100];
};
