import React, { useState, useEffect, Fragment } from "react";
import ResultsBadge from "./ResultsBadge";
import { calculateStampDuty } from "../../utils/calculators";
// import { priceFormatter } from "../../utils/PriceFormatter";
import Alert from "../Alert";
import { priceFormatter } from "../../utils/PriceFormatter";

function StampDutyCalculator({
  formData,
  // calculationsData,
  calculatedPropertyPurchasePrice,
}) {
  const [calculatorResults, setCalculatorResults] = useState({});

  useEffect(() => {
    try {
      // const key = formData.helpToBuy !== 1 ? 1 : 4;

      // const calcPropertyPurchasePrice =
      //   calculationsData.affordabilityResults[key];

      setCalculatorResults(
        calculateStampDuty(
          formData.propertyPurchasePrice,
          formData.buyToLet,
          formData.firstTimeBuyer,
        ),
      );
      // handleHelpText(data);
    } catch {
      return false;
    }
  }, [formData, calculatedPropertyPurchasePrice]);

  // const pctFormatter = (value) => {
  //   if (value) return value.toFixed(2) + "%";
  //   else return "0.00%";
  // };
  // console.log("SD CALC RES", calculatorResults);
  return (
    <div className="mb-0 rounded-[10px] bg-stone p-4">
      {/* <div className="mb-8"> */}
      <h2 className="mb-4 text-center font-heading text-3xl text-firgreen">
        Your stamp duty
      </h2>

      <Fragment>
        <div className="mb-4 divide-y divide-gray border-y border-gray">
          <ResultsBadge
            size="large"
            format="price"
            result={calculatorResults[0]}
            label="Stamp duty to pay"
            help={null}
            last={false}
          />
          <ResultsBadge
            size="large"
            format="percentage"
            result={calculatorResults[1]}
            label="Effective stamp duty rate"
            help={null}
            last={true}
          />
        </div>
        {formData.propertyPurchasePrice ? (
          <div className="mb-0">
            <Alert
              type="info"
              text={
                <span>
                  The results above are based on the property purchase price of{" "}
                  <span className="font-bold">
                    {priceFormatter(formData.propertyPurchasePrice)}
                  </span>
                </span>
              }
              background="white"
            />
          </div>
        ) : null}
      </Fragment>
    </div>
  );
}

export default StampDutyCalculator;
