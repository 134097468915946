import Joi from "joi";

export const validateProperty = (property, schemaObj, errors, setErrors) => {
  //console.log("LETS VALIDATE!", property);

  const { name, value } = property;

  const schema = Joi.object({
    [name]: schemaObj[name],
  });

  const { error } = schema.validate({ [name]: value });

  if (error) {
    setErrors({ ...errors, [name]: error.details[0].message });
    //console.log("ERROR MSG!", error.details[0].message);
  } else {
    //console.log("RESET ERROR!");

    const errorsObj = { ...errors };
    // console.log("ERROR OBJ", errorsObj);

    delete errorsObj[name];
    setErrors(errorsObj);
  }
};

export const validateForm = (data, schemaObj) => {
  const schema = Joi.object(schemaObj);

  const options = { abortEarly: false };
  const { error } = schema.validate(data, options);
  if (!error) return null;

  return error;

  //   const errors = {};
  //   for (let item of error.details) errors[item.path[0]] = item.message;
  //   return errors;
};
