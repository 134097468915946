import React from "react";

const getDefaultClasses = () => {
  return "inline-flex items-center justify-center border border-transparent rounded-full text-white transition duration-[250ms] disabled:opacity-25";
};

const getBackgroundClasses = (color) => {
  switch (color) {
    case "coral":
      return "bg-coral hover:bg-coral-dark disabled:hover:bg-coral";
  }
};

export function ButtonDefault({ color, label, onClick, disabled = false }) {
  return (
    <button
      type="button"
      className={`${getDefaultClasses()} min-w-[145px] px-[25px] py-[6px] text-[0.8rem] font-semibold ${getBackgroundClasses(
        color
      )}`}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
}

export function ButtonLink({ label, onClick }) {
  return (
    <button
      type="button"
      className="text-[1rem] text-firgreen hover:underline transition duration-[250ms]"
      onClick={onClick}
    >
      {label}
    </button>
  );
}

export function ButtonLarge({ color, label, onClick, disabled = false }) {
  return (
    <button
      type="button"
      className={`${getDefaultClasses()} min-w-[145px] px-[25px] py-[10px] text-[0.9rem] font-bold ${getBackgroundClasses(
        color
      )}`}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
}

export function ButtonSmall({ color, label, onClick, disabled = false }) {
  return (
    <button
      type="button"
      className={`${getDefaultClasses()} py-1 px-4 text-sm font-semibold ${getBackgroundClasses(
        color
      )}`}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
}
