import React, { useContext, useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import FormContact from "../components/FormContact";
import { ButtonDefault } from "../components/Buttons";
import AppContext from "../Context/AppContext";

function ContactPage() {
  let navigate = useNavigate();

  const { formData, setFormData, appointment, setAppointment, setPageHeight } =
    useContext(AppContext);

  useEffect(() => {
    setPageHeight(true);
  }, []);

  return (
    <>

      <div className="w-full px-[15px]">
      {/* <div className="mx-auto w-full px-[15px] sm:max-w-3xl"> */}

        <h2 className="font-heading mb-4 text-3xl font-bold leading-none text-firgreen">
          Contact development
        </h2>
        <div className="border-gray-light mb-8 border-b"></div>
        {!appointment && (
          <>
            <p className="text-md mb-4 text-gray">
              If you have a question about this development we'd love to hear
              from you. We'll get back to you as soon as you we can.
            </p>
            <FormContact
              formData={formData}
              setFormData={setFormData}
              appointment={appointment}
              setAppointment={setAppointment}
            />
          </>
        )}
        {appointment && (
          <div className="text-left text-gray">
            <div id="message">
              {/* <p className="text-md mb-4">
                Many thanks for your enquiry. It&rsquo;s been sent to the sales
                team
                {formData.developmentName ? ` at ${formData.developmentName}` : ""} who
                will get back to you as soon as possible.
              </p> */}
            </div>

            <ButtonDefault
              color="coral"
              label="Return"
              onClick={() => navigate("/")}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default ContactPage;
