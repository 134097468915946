import React from "react";
import percentage from "calculate-percentages";
import { priceFormatter } from "./PriceFormatter";

export const depositCheck = (propertyPurchasePrice, deposit, buyToLet) => {
  const percent = buyToLet ? 20 : 5;

  const requiredDepositAmount = percentage.of(percent, propertyPurchasePrice);

  return requiredDepositAmount > deposit
    ? [
        "warning",
        <span>
          Based on your chosen property purchase price of{" "}
          <span className="font-bold">
            {priceFormatter(propertyPurchasePrice)}
          </span>{" "}
          your deposit needs to be a minimum of{" "}
          <span className="font-bold">{percent}%</span> for{" "}
          {buyToLet ? "some UK buy to let" : "the majority of UK"} mortgage
          lenders. Please increase your deposit amount.
        </span>,
        "white",
      ]
    : [
        `${buyToLet ? "info" : "success"}`,
        <span>
          Based on your chosen property purchase price of{" "}
          <span className="font-bold">
            {priceFormatter(propertyPurchasePrice)}
          </span>
          , your deposit meets the minimum requirement of{" "}
          <span className="font-bold">{percent}%</span> for{" "}
          {buyToLet ? "some UK buy to let" : "the majority of UK"} mortgage
          lenders.
        </span>,
        "white",
      ];
};
