import React from "react";
import FormTooltip from "./FormTooltip";
import Alert from "../Alert";

function SelectMenu({ name, label, options, help, value, onChange, error }) {
  const selectedValue = options.find((option) => value === option.value);

  return (
    <div className="">
      <div className="flex items-start justify-between mb-2">
        <label htmlFor={name} className="block text-gray">
          {label}
        </label>
        {help && <FormTooltip text={help} />}
      </div>
      <select
        id={name}
        name={name}
        value={selectedValue ? selectedValue.value : 0}
        className=" block w-full pl-3 pr-10 py-2 bg-white dark:bg-black text-gray border-firgreen focus:ring-skyblue focus:border-skyblue rounded-[0.25rem] mb-1"
        onChange={onChange}
      >
        {options.map((option) => (
          <option
            key={option._id}
            value={option.value}
            disabled={option.disabled}
          >
            {option.option}
          </option>
        ))}
      </select>
      {error && <Alert type="danger" text={error} />}
    </div>
  );
}

export default SelectMenu;
