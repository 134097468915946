import React, {  useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Joi from "joi";
import { isEmpty } from "lodash";
import shortid from "shortid";
import InputField from "./form/InputField";
import SelectMenu from "./form/SelectMenu";
import Textarea from "./form/Textarea";
//import SingleCheckbox from "./form/SingleCheckbox";
import HiddenField from "./form/HiddenField";
import { ButtonLarge, ButtonLink } from "./Buttons";
import { validateProperty, validateForm } from "../utils/validation";

function FormContact({ formData, setFormData, setAppointment }) {
  let navigate = useNavigate();

  const [data, setData] = useState({
    title: "",
    firstName: "",
    surname: "",
    emailAddress: "",
    mobileNumber: "",
    message: "",
  });
  const [formDataChange, setFormDataChange] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {

    if (!isEmpty(formDataChange))
      validateProperty(formDataChange, schemaObj, errors, setErrors);

  }, [formDataChange]);

  const schemaObj = {
    title: Joi.string().required().invalid("0").messages({
      "any.invalid": "Please enter your title",
    }),
    firstName: Joi.string().required().messages({
      "string.empty": "Please enter your first name",
    }),
    surname: Joi.string().required().messages({
      "string.empty": "Please enter your surname",
    }),
    emailAddress: Joi.string().email({ tlds: false }).required().messages({
      "string.empty": "Please enter your email address",
      "string.email": "Please enter a valid email address",
    }),
    mobileNumber: Joi.any(),
    message: Joi.any(),
  };


  const handleChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: currentTarget.value,
    };
    doAfterChange(input);
  };

  const doAfterChange = (input) => {
    setData({ ...data, [input.name]: input.value });
    setFormDataChange(input);
  };

  const handleSubmit = async () => {
    const id = shortid.generate().toUpperCase();

    // GET FORM DATA AS SERIALIZED ARRAY
    // https://barker.codes/blog/serialize-form-data-into-a-query-string-in-vanilla-js

    const form = document.getElementById("contact-form");
    const formData = new FormData(form);

    const serializeArray = () => {
      // Create a new URLSearchParams object
      const params = new URLSearchParams(formData);
      // Return the query string
      return params.toString();
    };

    const http = new XMLHttpRequest();
    http.onreadystatechange = () => {
      const message = document.getElementById("message");
      if (http.status === 200 && http.readyState === 4) 
        if (message && http.responseText) return (message.innerHTML = http.responseText);
      else {
        if (message)
          return (message.innerHTML = '<p class="text-md mb-4">Sorry - Due to an unknown error your enquiry could not be sent.</p><p class="text-md mb-4">Please try again later.</p>');
      } 
    };
    http.open("POST", form.action, true);
    http.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    http.send(serializeArray());

    setAppointment(true);
    setFormData({ appointment: { data, id } });
  };

  return (
    <Fragment>
      <form
        action="https://ph-calc.co.uk/form.php"
        method="post"
        id="contact-form"
      >
        <HiddenField name="price" value={formData.propertyPurchasePrice} />
        <HiddenField name="houseType" value={formData.houseType} />
        <HiddenField name="developmentName" value={formData.developmentName} />
        <HiddenField name="development" value={formData.development} />
        <HiddenField name="company" value={formData.company} />
        <HiddenField name="plot" value={formData.plot} />
        <HiddenField name="brand" value={formData.brand} />

        <div className="mb-4 w-full max-w-sm">
          <div className="">
            <SelectMenu
              name="title"
              label="Title"
              options={[
                { _id: 0, value: "0", option: "Please select" },
                { _id: 1, value: "1", option: "Mr" },
                { _id: 2, value: "2", option: "Mrs" },
                { _id: 3, value: "3", option: "Miss" },
                { _id: 4, value: "4", option: "Ms" },
                { _id: 5, value: "5", option: "Sir" },
                { _id: 6, value: "6", option: "Dr" },
              ]}
              help={null}
              value={data["title"]}
              onChange={handleChange}
              error={errors["title"]}
            />
          </div>
        </div>
        <div className="mb-4 w-full max-w-sm">
          <div className="">
            <InputField
              name="firstName"
              label="First name"
              type="text"
              help={null}
              value={data["firstName"]}
              onChange={handleChange}
              error={errors["firstName"]}
            />
          </div>
        </div>
        <div className="mb-4 w-full max-w-sm">
          <div className="">
            <InputField
              name="surname"
              label="Surname"
              type="text"
              help={null}
              value={data["surname"]}
              onChange={handleChange}
              error={errors["surname"]}
            />
          </div>
        </div>
        <div className="mb-4 w-full max-w-sm">
          <div className="">
            <InputField
              name="emailAddress"
              label="Email address"
              type="text"
              help={null}
              value={data["emailAddress"]}
              onChange={handleChange}
              error={errors["emailAddress"]}
            />
          </div>
        </div>
        <div className="mb-4 w-full max-w-sm">
          <div className="">
            <InputField
              name="mobileNumber"
              label="Mobile number"
              type="text"
              help={null}
              value={data["mobileNumber"]}
              onChange={handleChange}
              error={errors["mobileNumber"]}
            />
          </div>
        </div>
        <div className="mb-8 w-full max-w-sm">
          <div className="">
            <Textarea
              name="message"
              label="Message"
              help={null}
              value={data["message"]}
              onChange={handleChange}
              error={errors["message"]}
            />
          </div>
        </div>

        <div className="mb-0 w-full max-w-sm">
          <div className="">
            <ButtonLarge
              color="coral"
              label="Submit"
              onClick={handleSubmit}
              disabled={validateForm(data, schemaObj)}
            />
            <span className="ml-4">
              <ButtonLink label="Cancel" onClick={() => navigate("/")} />
            </span>
          </div>
        </div>
      </form>
    </Fragment>
  );
}

export default FormContact;
