import React from "react";
import NumberFormat from "react-number-format";
import FormTooltip from "./FormTooltip";
import Alert from "../Alert";
import AppContext from "../../Context/AppContext";

function InputPriceField({
  name,
  label,
  help,
  value,
  onChange,
  error,
  disabled = false,
}) {
  //const { setFormData } = useContext(AppContext);

  // const handleInputPriceFieldChange = ({ currentTarget }) => {
  //   const input = {
  //     value: Number(currentTarget.value.replace(/[^0-9.-]+/g, "")),
  //     name: currentTarget.name,
  //   };
  //   setFormData({ [input.name]: input.value });
  //   onFormDataChange(input);
  // };

  return (
    <div className="">
      <div className="flex items-start justify-between mb-2">
        <label htmlFor={name} className="block text-gray">
          {label}
        </label>
        {help && <FormTooltip text={help} />}
      </div>

      <div className="mb-1">
        <NumberFormat
          //type={type}
          name={name}
          id={name}
          value={value ? value : null}
          className="block w-full text-gray disabled:bg-gray/10 border-firgreen bg-white rounded-[0.25rem] focus:ring-skyblue focus:border-skyblue"
          aria-describedby={`${name}-help`}
          onChange={onChange}
          //displayType={"text"}
          thousandSeparator={true}
          prefix={"£"}
          decimalScale={2}
          //allowEmptyFormatting={true}
          disabled={disabled}
        />
      </div>
      {error && <Alert type="danger" text={error} />}
    </div>
  );
}

export default InputPriceField;
