import React from "react";
import FormTooltip from "./FormTooltip";
import Alert from "../Alert";
// import AppContext from "../../Context/AppContext";

function RadioGroup({ name, label, options, help, value, onChange, error }) {
  // const { setFormData } = useContext(AppContext);

  // const handleRadioGroupChange = ({ currentTarget }) => {
  //   const input = {
  //     name: currentTarget.name,
  //     value: parseInt(currentTarget.value),
  //   };
  //   setFormData({ [input.name]: input.value });
  //   onFormDataChange(input);
  // };

  const getClasses = (value, selected) => {
    return value === selected
      ? "bg-firgreen hover:bg-firgreen-light border border-firgreen hover:border-firgreen-light text-white"
      : "bg-white hover:bg-stone border border-firgreen text-gray";
  };

  return (
    <div className="">
      <div className="flex items-start justify-between mb-2">
        <p className="text-gray">{label}</p>
        {help && <FormTooltip text={help} />}
      </div>

      <fieldset className="mb-1">
        <legend className="sr-only">{label}</legend>
        <div className="gap-1 flex">
          {/* <!--
      In Stock: "cursor-pointer", Out of Stock: "opacity-25 cursor-not-allowed"
      Active: "ring-2 ring-offset-2 ring-indigo-500"
      Checked: "bg-indigo-600 border-transparent text-white hover:bg-indigo-700", Not Checked: "bg-white border-gray-200 text-gray-900 hover:bg-gray-50"
    --> */}
          {options.map((option) => (
            <label
              key={option._id}
              className={`flex sm:flex-1 items-center justify-center w-full max-w-[4rem] rounded-[0.25rem] mt-1 py-2 px-2 font-bold transition duration-[250ms] cursor-pointer ${getClasses(
                value,
                option._id
              )}`}
            >
              <input
                type="radio"
                name={name}
                value={option._id}
                className="sr-only"
                aria-labelledby={`${name}-${option._id}`}
                // checked={value === option._id}
                onChange={onChange}
              />
              <p id={`${name}-${option._id}`}>{option.option}</p>
            </label>
          ))}
        </div>
      </fieldset>
      {error && <Alert type="danger" text={error} />}
    </div>
  );
}

export default RadioGroup;
