import React, { useContext, useState, useEffect, Fragment } from "react";
import DepositChecker from "./widgets/DepositChecker";
import MortgageCalculator from "./widgets/MortgageCalculator";
import StampDutyCalculator from "./widgets/StampDutyCalculator";
import ContactDevelopment from "./widgets/ContactDevelopment";
import AppContext from "../Context/AppContext";
import { calculateAffordability } from "../utils/calculators";
import options from "../data/options.json";

function Results(props) {
  const { formData, searchParams, appointment, setPageHeight } =
    useContext(AppContext);

  const [calculatedLaonAmount, setCalculatedLaonAmount] = useState(null);
  const [calculatedPropertyPurchasePrice, setCalculatedPropertyPurchasePrice] =
    useState(null);
  const [propertyRegionDetails, setPropertyRegionDetails] = useState({});

  useEffect(() => {
    setPageHeight(true);
  }, [calculatedLaonAmount, calculatedPropertyPurchasePrice]);

  useEffect(() => {
    try {
      const propertyRegion = getOptionSet("propertyRegions").find(
        (region) => Number(formData.propertyRegion) === region._id,
      );

      setPropertyRegionDetails(propertyRegion);

      const results = calculateAffordability(
        formData.deposit,
        formData.buyToLet,
      );

      setCalculatedLaonAmount(
        formData.propertyPurchasePrice - formData.deposit,
      );

      setCalculatedPropertyPurchasePrice(formData.propertyPurchasePrice);
    } catch {
      return false;
    }
  }, [formData, calculatedPropertyPurchasePrice]);

  const getOptionSet = (requiredSet) => {
    const filteredOptionSet = options.filter(
      (o) => o.optionSet === requiredSet,
    );
    return filteredOptionSet[0].options;
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-[30px]">
      <div className="mx-auto w-full px-[15px] sm:max-w-[503px] sm:px-0">
        <DepositChecker
          formData={formData}
          calculatedPropertyPurchasePrice={calculatedPropertyPurchasePrice}
          propertyRegionDetails={propertyRegionDetails}
        />
        <MortgageCalculator
          formData={formData}
          calculatedLaonAmount={calculatedLaonAmount}
        />
      </div>
      <div className="mx-auto w-full px-[15px] sm:max-w-[503px] sm:px-0">
        <StampDutyCalculator
          formData={formData}
          calculatedPropertyPurchasePrice={calculatedPropertyPurchasePrice}
        />
        {!appointment && <ContactDevelopment />}
        <p className="text-sm text-gray mt-8">
          Persimmon homes do not provide mortgage advice.
          <br />
          These results can only be used as a guide.
          <br />
          For an exact borrowing figure please contact us, we will then refer
          you to a free independent recommended mortgage adviser.
        </p>
      </div>
    </div>
  );
}

export default Results;
