import React from "react";

function HiddenField({ name, value }) {
  return (
    <input
      type="hidden"
      name={name}
      id={name}
      value={value ? value : ""}
      className="hidden"
    />
  );
}

export default HiddenField;
