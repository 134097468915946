import React, { useContext, useState, useEffect } from "react";
import Joi from "joi";
import { isEmpty } from "lodash";
import RadioGroup from "./form/RadioGroup";
import InputPriceField from "./form/InputPriceField";
import Alert from "./Alert";
import {
  getEquity,
  getEquityMessage,
  getEquityDepositMessage,
} from "../utils/getEquity";
import { validateProperty } from "../utils/validation";

import AppContext from "../Context/AppContext";
import options from "../data/options.json";

function FormMain(props) {
  const { formData, setFormData, searchParams } = useContext(AppContext);

  const [formDataChange, setFormDataChange] = useState({});
  const [errors, setErrors] = useState({});
  const [alerts, setAlerts] = useState({});
  const [resetDepositField, setResetDepositField] = useState(false);

  useEffect(() => {
    if (!isEmpty(formDataChange))
      validateProperty(formDataChange, schemaObj, errors, setErrors);

    const { name, value } = formDataChange;

    if (name === "firstTimeBuyer")
      if (value === 0) setFormData({ helpToBuy: null, propertyRegion: null });
      else {
        setFormData({ propertyToSell: null, propertyToSellValue: null });
      }

    if (name === "helpToBuy" && value === 1)
      setFormData({
        buyToLet: null,
      });
    if (name === "helpToBuy" && value === 0)
      setFormData({ propertyRegion: null });

    if (name === "buyToLet" && value === 1)
      setFormData({
        helpToBuy: null,
        propertyRegion: null,
      });

    if (name === "propertyToSell" && value === 0) {
      if (formData.propertyToSellValue || formData.outstandingMortgageAmount) {
        setResetDepositField(true);
      }
      setFormData({
        propertyToSellValue: null,
        outstandingMortgageAmount: null,
      });
      setAlerts({});
    }

    // EQUITY ALERTS ????  -- remove unused alerts
    const equity = getEquity(
      formData.propertyToSellValue,
      formData.outstandingMortgageAmount,
    );

    const equityMessage = getEquityMessage(
      formData.propertyToSellValue,
      formData.outstandingMortgageAmount,
    );
    const equityDepositMessage = getEquityDepositMessage(
      formData.propertyToSellValue,
      formData.outstandingMortgageAmount,
    );

    if (
      name === "propertyToSellValue" ||
      name === "outstandingMortgageAmount"
    ) {
      setAlerts({
        ...alerts,
        equity: equityMessage,
        equityDeposit: equityDepositMessage,
      });

      if (formData.propertyToSellValue && formData.outstandingMortgageAmount) {
        if (equity >= 0) setFormData({ deposit: equity });
        else {
          // ??? Is this line needed
          setFormData({ buyToLet: null });
        }
      }
    }

    if (name === "deposit") {
      const message = value === equity ? equityDepositMessage : null;
      setAlerts({
        ...alerts,
        equityDeposit: message,
      });
    }
  }, [formDataChange]);

  useEffect(() => {
    setResetDepositField(false);
    setFormData({ deposit: null });
    setErrors({ deposit: null });
  }, [resetDepositField]);

  const schemaObj = {
    jointApplication: Joi.number().required(),
    firstTimeBuyer: Joi.number().required(),
    buyToLet: Joi.number().required(),
    propertyPurchasePrice: Joi.number().required().invalid(0).messages({
      "any.invalid": "Please enter the property purchase price",
    }),
    propertyRegion: Joi.number().required().invalid(0).messages({
      "any.invalid": "Please select a region",
    }),
    propertyToSell: Joi.number().required(),
    propertyToSellValue: Joi.number().required().invalid(0).messages({
      "any.invalid": "Please enter the value of the property you're selling",
    }),
    outstandingMortgageAmount: Joi.number().required().invalid(0).messages({
      "any.invalid":
        "Please enter the amount outstanding on your current mortgage",
    }),
    deposit: Joi.number().required().invalid(0).messages({
      "any.invalid": "Please enter the deposit amount",
    }),
    helpToBuy: Joi.number().required(),
    income: Joi.number().required().invalid(0).messages({
      "any.invalid": "Please enter the total income",
    }),
  };

  const handleChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: currentTarget.value,
    };
    doAfterChange(input);
  };

  const handleRadioChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: parseInt(currentTarget.value),
    };
    doAfterChange(input);
  };

  const handlePriceChange = ({ currentTarget }) => {
    const input = {
      value: Number(currentTarget.value.replace(/[^0-9.-]+/g, "")),
      name: currentTarget.name,
    };
    doAfterChange(input);
  };

  const doAfterChange = (input) => {
    setFormData({ ...formData, [input.name]: input.value });
    setFormDataChange(input);
  };

  // Move to utils at later date
  const getOptionSet = (requiredSet) => {
    const filteredOptionSet = options.filter(
      (o) => o.optionSet === requiredSet,
    );
    return filteredOptionSet[0].options;
  };

  //console.log("searchParams", searchParams);

  return (
    <>
      <div className="mb-6">
        <InputPriceField
          name="propertyPurchasePrice"
          label="What is the property purchase price?"
          help={null}
          value={formData["propertyPurchasePrice"]}
          onChange={handlePriceChange}
          error={errors["propertyPurchasePrice"]}
        />
      </div>

      <div className="mb-6">
        <RadioGroup
          name="jointApplication"
          label="Is this a single or joint purchase?"
          options={[
            { _id: 0, option: "Single" },
            { _id: 1, option: "Joint" },
          ]}
          help={null}
          value={formData["jointApplication"]}
          onChange={handleRadioChange}
          error={errors["jointApplication"]}
        />
      </div>
      <div className="mb-6">
        <RadioGroup
          name="firstTimeBuyer"
          label="Are you purchasing your first home?"
          options={[
            { _id: 1, option: "Yes" },
            { _id: 0, option: "No" },
          ]}
          help={null}
          value={formData["firstTimeBuyer"]}
          onChange={handleRadioChange}
          error={errors["firstTimeBuyer"]}
        />
      </div>
      <div className="mb-6">
        <RadioGroup
          name="buyToLet"
          label="Are you purchasing this property as a buy to let?"
          options={[
            { _id: 1, option: "Yes" },
            { _id: 0, option: "No" },
          ]}
          help={null}
          value={formData["buyToLet"]}
          onChange={handleRadioChange}
          error={errors["buyToLet"]}
        />
        {formData.buyToLet === 1 ? (
          <div className="mt-1">
            <Alert
              type="info"
              text="The deposit required will depend on rental income you receive."
            />
          </div>
        ) : null}
      </div>
      {formData.firstTimeBuyer === 0 ? (
        <div className="mb-6">
          <RadioGroup
            name="propertyToSell"
            label="Are you selling a property?"
            options={[
              { _id: 1, option: "Yes" },
              { _id: 0, option: "No" },
            ]}
            help={null}
            value={formData["propertyToSell"]}
            onChange={handleRadioChange}
            error={errors["propertyToSell"]}
          />
        </div>
      ) : null}
      {formData.firstTimeBuyer === 0 && formData.propertyToSell === 1 ? (
        <div className="mb-6 flex flex-col">
          <InputPriceField
            name="propertyToSellValue"
            label="What is the value of the property you're selling?"
            help={null}
            value={formData["propertyToSellValue"]}
            onChange={handlePriceChange}
            error={errors["propertyToSellValue"]}
          />
        </div>
      ) : null}
      {formData.firstTimeBuyer === 0 && formData.propertyToSell === 1 ? (
        <div className="mb-6">
          <InputPriceField
            name="outstandingMortgageAmount"
            label="How much do you owe on your existing mortgage?"
            help={null}
            value={formData["outstandingMortgageAmount"]}
            onChange={handlePriceChange}
            error={errors["outstandingMortgageAmount"]}
          />
          {alerts.equity && (
            <div className="mt-1">
              <Alert type={alerts.equity[0]} text={alerts.equity[1]} />
            </div>
          )}
        </div>
      ) : null}
      {!resetDepositField ? (
        <div className="mb-0">
          <InputPriceField
            name="deposit"
            label="What is your total deposit for your purchase?"
            help={
              <span>
                The amount of money you can put towards the cost of your new
                property. This could be a family gift or savings.
                {/* {formData.propertyToSell
                  ? ". Adjust by adding funds or subtracting existing equity"
                  : null}
                . */}
              </span>
            }
            value={formData["deposit"]}
            onChange={handlePriceChange}
            error={errors["deposit"]}
          />
          {alerts.equityDeposit && (
            <div className="mt-1">
              <Alert
                type={alerts.equityDeposit[0]}
                text={alerts.equityDeposit[1]}
              />
            </div>
          )}
          {alerts.deposit && (
            <div className="mt-1">
              <Alert type="warning" text={alerts.deposit} />
            </div>
          )}
        </div>
      ) : null}
    </>
  );
}

export default FormMain;
