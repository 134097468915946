import React from "react";
import FormTooltip from "./FormTooltip";
import Alert from "../Alert";

function Textarea({ name, label, help, value, onChange, error }) {
  return (
    <div className="">
      <div className="flex items-start justify-between mb-2">
        <label htmlFor={name} className="block text-gray">
          {label}
        </label>
        {help && <FormTooltip text={help} />}
      </div>

      <div className="mb-1">
        <textarea
          name={name}
          id={name}
          value={value ? value : ""}
          rows="4"
          className="block w-full text-gray disabled:bg-gray/10 border-firgreen bg-white rounded-[6px] focus:ring-skyblue focus:border-skyblue"
          aria-describedby={`${name}-help`}
          onChange={onChange}
        />
      </div>
      {error && <Alert type="danger" text={error} />}
    </div>
  );
}

export default Textarea;
